<template>
  <v-dialog v-model="dialog" max-width="1000px" @click:outside="closeDialog()">
    <v-card>
      <v-card-title class="d-flex justify-center py-6 mb-6 blue lighten-1 mc2i white--text">
        <span class="headline">Modifier les RDVs de {{ candidat.prenom }} {{ candidat.nom }}</span>
      </v-card-title>
      <!--Choix des dates de RDV -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid">
            <v-row>
              <!--RDV1 -->
              <v-col cols="12" sm="12" md="3">
                <v-menu v-model="candidatRdv1Menu" :nudge-right="40" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="candidatRdv1" label="RDV1" prepend-icon="mdi-calendar" readonly clearable
                      clear-icon="mdi-close-circle" v-bind="attrs" v-on="on" :error-messages="rdv1Rule()" outlined dense
                      rounded background-color="grey lighten-4">
                    </v-text-field>
                  </template>
                  <v-date-picker class="rounded-xl" :first-day-of-week="1" v-model="candidat.rdv1"
                    @input="candidatRdv1Menu = false; booldaterdv1 = false" locale="fr-FR"></v-date-picker>
                </v-menu>

              </v-col>
              <!--Recruteur 1 -->
              <v-col cols="12" sm="12" md="3">
                <v-autocomplete v-model="candidat.trigramme1" :items="champs.trigrammes" prepend-icon="mdi-account"
                  clearable clear-icon="mdi-close-circle" label="Recruteur 1" :error-messages="trigramme1Rule()" outlined
                  dense rounded background-color="grey lighten-4">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="6" v-if="candidat.rdv1 && candidat.trigramme1">
                <v-textarea prepend-icon="mdi-comment" counter="1000" label="Commentaire RDV1" rows="1"
                  :rules="rules_rdv_comment" v-model="candidat.commentaire_rdv1" outlined dense rounded
                  background-color="grey lighten-4">
                </v-textarea>
              </v-col>
            </v-row>
            <v-row v-if="candidat[profil] != null
              ? !(
                candidat[profil].toLowerCase().includes('stagiaire') ||
                candidat[profil].toLowerCase().includes('alternant')
              )
              : true">
              <!--RDV2 -->
              <v-col cols="12" sm="12" md="3">
                <v-menu v-model="candidatRdv2Menu" :nudge-right="40" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="candidatRdv2" label="RDV2" prepend-icon="mdi-calendar" readonly clearable
                      clear-icon="mdi-close-circle" v-bind="attrs" v-on="on" :error-messages="rdv2Rule()" outlined dense
                      rounded background-color="grey lighten-4">
                    </v-text-field>
                  </template>
                  <v-date-picker class="rounded-xl" :first-day-of-week="1" v-model="candidat.rdv2"
                    @input="candidatRdv2Menu = false; booldaterdv2 = false" locale="fr-FR"></v-date-picker>
                </v-menu>

              </v-col>
              <!--Recruteur 2 -->
              <v-col cols="12" sm="12" md="3">
                <v-autocomplete v-model="candidat.trigramme2" :items="champs.trigrammes" prepend-icon="mdi-account"
                  clearable clear-icon="mdi-close-circle" label="Recruteur 2" :error-messages="trigramme2Rule()" outlined
                  dense rounded background-color="grey lighten-4">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="6" v-if="candidat.rdv2 && candidat.trigramme2">
                <v-textarea prepend-icon="mdi-comment" counter="1000" label="Commentaire RDV2" rows="1"
                  :rules="rules_rdv_comment" v-model="candidat.commentaire_rdv2" outlined dense rounded
                  background-color="grey lighten-4">
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <!--RDV3 -->
              <v-col cols="12" sm="12" md="3">
                <v-menu v-model="candidatRdv3Menu" :nudge-right="40" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="candidatRdv3" label="RDV3" prepend-icon="mdi-calendar" readonly clearable
                      clear-icon="mdi-close-circle" v-bind="attrs" v-on="on" :error-messages="rdv3Rule()" outlined dense
                      rounded background-color="grey lighten-4">
                    </v-text-field>
                  </template>
                  <v-date-picker class="rounded-xl" :first-day-of-week="1" v-model="candidat.rdv3"
                    @input="candidatRdv3Menu = false; booldaterdv3 = false" locale="fr-FR"></v-date-picker>
                </v-menu>

              </v-col>

              <!--Recruteur 3 -->
              <v-col cols="12" sm="12" md="3">
                <v-autocomplete v-model="candidat.trigramme3" :items="champs.trigrammes" prepend-icon="mdi-account"
                  clearable clear-icon="mdi-close-circle" label="Recruteur 3" :error-messages="trigramme3Rule()" outlined
                  dense rounded background-color="grey lighten-4">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="6" v-if="candidat.rdv3 && candidat.trigramme3">
                <v-textarea prepend-icon="mdi-comment" counter="1000" label="Commentaire RDV3" rows="1"
                  :rules="rules_rdv_comment" v-model="candidat.commentaire_rdv3" outlined dense rounded
                  background-color="grey lighten-4">
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <!--RDV4 -->
              <v-col cols="12" sm="12" md="3">
                <v-menu v-model="candidatRdv4Menu" :nudge-right="40" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="candidatRdv4" label="RDV4" prepend-icon="mdi-calendar" readonly clearable
                      clear-icon="mdi-close-circle" v-bind="attrs" v-on="on" :error-messages="rdv4Rule()" outlined dense
                      rounded background-color="grey lighten-4">
                    </v-text-field>
                  </template>
                  <v-date-picker :first-day-of-week="1" class="rounded-xl" v-model="candidat.rdv4"
                    @input="candidatRdv4Menu = false; booldaterdv4 = false" locale="fr-FR"></v-date-picker>
                </v-menu>

              </v-col>
              <!--Recruteur 4 -->
              <v-col cols="12" sm="12" md="3">
                <v-autocomplete v-model="candidat.trigramme4" :items="champs.trigrammes" prepend-icon="mdi-account"
                  clearable clear-icon="mdi-close-circle" label="Recruteur 4" required :error-messages="trigramme4Rule()"
                  outlined dense rounded background-color="grey lighten-4">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="6" v-if="candidat.rdv4 && candidat.trigramme4">
                <v-textarea prepend-icon="mdi-comment" counter="1000" label="Commentaire RDV4" rows="1"
                  :rules="rules_rdv_comment" v-model="candidat.commentaire_rdv4" outlined dense rounded
                  background-color="grey lighten-4">
                </v-textarea>
              </v-col>
            </v-row>
            <!--Offre -->
            <v-row>
              <v-col cols="12" sm="12" md="3">
                <v-menu v-model="candidatOffreMenu" :nudge-right="40" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="candidatOffre" label="Offre" prepend-icon="mdi-calendar" readonly clearable
                      clear-icon="mdi-close-circle" v-bind="attrs" v-on="on" outlined dense rounded
                      background-color="grey lighten-4">
                    </v-text-field>
                  </template>
                  <v-date-picker class="rounded-xl" :first-day-of-week="1" v-model="candidat.offre"
                    @input="candidatOffreMenu = false; booldateoffre = false" locale="fr-FR"></v-date-picker>
                </v-menu>

              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-autocomplete v-model="candidat.resp_offre" :items="champs.resp_offre.map(r=>r.trigramme_resp_offre)" clearable
                  clear-icon="mdi-close-circle" label="Responsable offre" prepend-icon="mdi-account" required
                  :error-messages="offreRule()" outlined dense rounded background-color="grey lighten-4">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="5">
                <v-menu v-model="candidatDemarrageMenu" :nudge-right="40" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="candidatDemarrage" label="Date de démarrage proposée"
                      prepend-icon="mdi-calendar-start" readonly clearable clear-icon="mdi-close-circle" v-bind="attrs"
                      v-on="on" outlined dense rounded background-color="grey lighten-4"
                      :error-messages="dateDemarrageRule">
                    </v-text-field>
                  </template>
                  <v-date-picker class="rounded-xl" :first-day-of-week="1" v-model="candidat.date_demarrage"
                    @input="candidatDemarrageMenu = false; booldatedemarrage = false" locale="fr-FR">
                  </v-date-picker>
                </v-menu>
              </v-col>

            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog()">
          Annuler
        </v-btn>
        <v-btn color="blue darken-1" text @click="updateRDV()" :disabled="!valid">
          Sauvegarder
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { updateCandidats } from "../../api/candidats";
import { dateEnToFr } from "../../commonFunction/date";

export default {
  data: () => ({
    rules_rdv_comment: [v => (v || '').length <= 1000 || '1000 caractères maximum'],
    dialog: false,
    idCandidat: null,
    type_candidat: "",
    candidat: {},
    valid: false,
    profil: "",
    candidatDemarrageMenu: false,
    candidatOffreMenu: false,
    candidatRdv4Menu: false,
    candidatRdv3Menu: false,
    candidatRdv2Menu: false,
    candidatRdv1Menu: false,
  }),
  computed: {
    dateDemarrageRule() {
      if (this.candidat.offre && !this.candidat.date_demarrage) {
        return 'La date de démarrage proposée est obligatoire lorsque vous avez une offre.';
      }
      return '';
    },
    candidatPrequa_tel() {
      return dateEnToFr(this.candidat.prequa_tel);
    },
    candidatRdv1: {
      get: function () {
        return dateEnToFr(this.candidat.rdv1);
      },
      set: function (newVal) {
        this.candidat.rdv1 = null;
      },
    },
    candidatRdv2: {
      get: function () {
        return dateEnToFr(this.candidat.rdv2);
      },
      set: function (newVal) {
        this.candidat.rdv2 = null;
      },
    },
    candidatRdv3: {
      get: function () {
        return dateEnToFr(this.candidat.rdv3);
      },
      set: function (newVal) {
        this.candidat.rdv3 = null;
      },
    },
    candidatRdv4: {
      get: function () {
        return dateEnToFr(this.candidat.rdv4);
      },
      set: function (newVal) {
        this.candidat.rdv4 = null;
      },
    },
    candidatOffre: {
      get: function () {
        return dateEnToFr(this.candidat.offre);
      },
      set: function (newVal) {
        this.candidat.offre = null;
      },
    },
    candidatDemarrage: {
      get: function () {
        return dateEnToFr(this.candidat.date_demarrage);
      },
      set: function (newVal) {
        this.candidat.date_demarrage = null;
      },
    },
    // Récupération des champs nécessaires au remplisssage du formulaire depuis le store
    champs() {
      return this.$store.getters.champs
    },
    // Récupération du role de l'utilisateur connecté depuis le store
    currentRole() {
      return this.$store.getters.currentRole;
    },
  },
  methods: {

    //Regles pour que l'utilisateur soit obliger d'ajouter les RDV et les trigrammes par pair
    rdv1Rule() {
      if (this.candidat.trigramme1 !== null && this.candidat.rdv1 === null)
        return "Un RDV1 doit aller avec un recruteur 1";
      else if (
        this.candidat.rdv2 !== null &&
        this.candidat.rdv1 === null &&
        (this.candidat[profil] != null
          ? !(
            this.candidat[profil].toLowerCase().includes("stagiaire") ||
            this.candidat[profil].toLowerCase().includes("alternant")
          )
          : true)
      )
        return "Le RDV1 doit-être rempli pour mettre un RDV2";
      else if (this.candidat.rdv3 !== null && this.candidat.rdv1 === null)
        return "Le RDV1 doit-être rempli pour mettre un RDV3";
      else if (this.candidat.rdv4 !== null && this.candidat.rdv1 === null)
        return "Le RDV1 doit-être rempli pour mettre un RDV4";
      else if (
        this.candidat.trigramme2 !== null &&
        this.candidat.rdv1 === null &&
        (this.candidat[profil] != null
          ? !(
            this.candidat[profil].toLowerCase().includes("stagiaire") ||
            this.candidat[profil].toLowerCase().includes("alternant")
          )
          : true)
      )
        return "Le RDV1 doit-être rempli pour mettre un recruteur 2";
      else if (this.candidat.trigramme3 !== null && this.candidat.rdv1 === null)
        return "Le RDV1 doit-être rempli pour mettre un recruteur 3";
      else if (this.candidat.trigramme4 !== null && this.candidat.rdv1 === null)
        return "Le RDV1 doit-être rempli pour mettre un recruteur 4";
      else if (this.candidat.offre !== null && this.candidat.rdv1 === null)
        return "Le RDV1 doit-être rempli pour mettre une offre";
      else return "";
    },
    trigramme1Rule() {
      if (this.candidat.rdv1 !== null && this.candidat.trigramme1 == null)
        return "Un recruteur 1 doit aller avec un RDV1";
      else if (
        this.candidat.trigramme2 !== null &&
        this.candidat.trigramme1 === null &&
        (this.candidat[profil] != null
          ? !(
            this.candidat[profil].toLowerCase().includes("stagiaire") ||
            this.candidat[profil].toLowerCase().includes("alternant")
          )
          : true)
      )
        return "Le recruteur 1 doit être rempli pour mettre un recruteur 2";
      else if (
        this.candidat.trigramme3 !== null &&
        this.candidat.trigramme1 === null
      )
        return "Le recruteur 1 doit être rempli pour mettre un recruteur 3";
      else if (
        this.candidat.trigramme4 !== null &&
        this.candidat.trigramme1 === null
      )
        return "Le recruteur 1 doit être rempli pour mettre un recruteur 4";
      else if (
        this.candidat.rdv2 !== null &&
        this.candidat.trigramme1 === null &&
        (this.candidat[profil] != null
          ? !(
            this.candidat[profil].toLowerCase().includes("stagiaire") ||
            this.candidat[profil].toLowerCase().includes("alternant")
          )
          : true)
      )
        return "Le recruteur 1 doit-être rempli pour mettre un RDV2";
      else if (this.candidat.rdv3 !== null && this.candidat.trigramme1 === null)
        return "Le recruteur 1 doit-être rempli pour mettre un RDV3";
      else if (this.candidat.rdv4 !== null && this.candidat.trigramme1 === null)
        return "Le recruteur 1 doit-être rempli pour mettre un RDV4";
      else if (
        this.candidat.offre !== null &&
        this.candidat.trigramme1 === null
      )
        return "Le recruteur 1 doit-être rempli pour mettre une offre";
      else return "";
    },
    rdv2Rule() {
      if (this.candidat.trigramme2 !== null && this.candidat.rdv2 === null)
        return "Un RDV2 doit aller avec un recruteur 2";
      // Ajoutez d'autres conditions en fonction de vos besoins
      else return "";
    },
    trigramme2Rule() {
      if (this.candidat.rdv2 !== null && this.candidat.trigramme2 === null)
        return "Un recruteur 2 doit aller avec un RDV2";
      // Ajoutez d'autres conditions en fonction de vos besoins
      else return "";
    },
    rdv3Rule() {
      if (this.candidat.trigramme3 !== null && this.candidat.rdv3 === null)
        return "Un RDV3 doit aller avec un recruteur 3";
      // Ajoutez d'autres conditions en fonction de vos besoins
      else return "";
    },
    trigramme3Rule() {
      if (this.candidat.rdv3 !== null && this.candidat.trigramme3 === null)
        return "Un recruteur 3 doit aller avec un RDV3";
      // Ajoutez d'autres conditions en fonction de vos besoins
      else return "";
    },
    rdv4Rule() {
      if (this.candidat.trigramme4 !== null && this.candidat.rdv4 === null)
        return "Un RDV4 doit aller avec un recruteur 4";
      // Ajoutez d'autres conditions en fonction de vos besoins
      else return "";
    },
    trigramme4Rule() {
      if (this.candidat.rdv4 !== null && this.candidat.trigramme4 === null)
        return "Un recruteur 4 doit aller avec un RDV4";
      // Ajoutez d'autres conditions en fonction de vos besoins
      else return "";
    },
    offreRule() {
      if (this.candidat.offre !== null && this.candidat.resp_offre === null)
        return "Le champ Offre ne peut pas être rempli sans un responsable";
      // Ajoutez d'autres conditions en fonction de vos besoins
      else return "";
    },

    openDialog(idCandidat, typeCandidat, candidat) {
      // Récupération de l'id, du type de candidat

      this.idCandidat = idCandidat;
      this.type_candidat = typeCandidat;
      // Copie de l'objet candidat
      this.candidat = { ...candidat };

      if (this.typeCandidat == "ope") {
        this.profil = 'profil'
      }
      else if (this.typeCandidat == "fct") {
        this.profil = 'profilFCT'
      }

      this.dialog = true;
    },
    async updateRDV() {
      this.candidat.commentaire_rdv1 = (this.candidat.rdv1 == null || this.candidat.trigramme1 == null) ? "" : this.candidat.commentaire_rdv1;
      this.candidat.commentaire_rdv2 = (this.candidat.rdv2 == null || this.candidat.trigramme2 == null) ? "" : this.candidat.commentaire_rdv2;
      this.candidat.commentaire_rdv3 = (this.candidat.rdv3 == null || this.candidat.trigramme3 == null) ? "" : this.candidat.commentaire_rdv3;
      this.candidat.commentaire_rdv4 = (this.candidat.rdv4 == null || this.candidat.trigramme4 == null) ? "" : this.candidat.commentaire_rdv4;
      // On modifie le candidat via l'API
      await updateCandidats(this.idCandidat, this.type_candidat, this.candidat);
      this.loading = false;
      this.closeDialog()
      // Actualisation du tableau des candidats
      this.$emit('rdvUpdated')
      this.closeDialog();
    },
    closeDialog() {
      this.dialog = false;
      this.$refs.form.reset();
    },

  },
};
</script>