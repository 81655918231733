<template>
  <div>
    <add-candidat ref="addcandidat"></add-candidat>
      <v-btn v-if="userRoles.includes(1) || userRoles.includes(2) || userRoles.includes(4) || userRoles.includes(5)" class="floating-btn" 
        fab color="primary"
        @click="openAddCandidat()"
        small>
        <v-icon>mdi-plus</v-icon>
      </v-btn>
  </div>
</template>

<script>
import AddCandidat from './form/AddCandidat.vue'

export default {
  components: {
    AddCandidat
  },
  data: () => ({
    alert: false
  }),
  computed: {
    userRoles() {
      return this.$store.getters.currentRoles;
    },
  },
  methods: {
    openAddCandidat() {
      //console.log("userRoles:", this.userRoles, "Type:", typeof this.userRoles);

      // Ouverture du dialogue d'ajout de candidat en fonction du type de candidat
      if (this.$route.path == "/candidats/ope") {
        this.$refs.addcandidat.openDialog("ope")
      }
      else if (this.$route.path == "/candidats/fct") {
        this.$refs.addcandidat.openDialog("fct")
      }
    }
  }
}
</script>

<style>
.floating-btn {
  position: fixed;
  bottom: 16px;
  left: 70px;
  z-index: 1;
}
</style>