<template>
    <v-dialog v-model="dialogRDV" max-width="720px">
      <v-card color="white">
        <v-card-title class="d-flex justify-center py-3 blue lighten-1 mc2i white--text">
          <span class="headline"> {{ candidat.prenom }} {{ candidat.nom }} </span>
        </v-card-title>
        <v-tabs v-model="tab" fixed-tabs>
          <v-tab>RDV</v-tab>
        </v-tabs>
  
      <v-tabs-items v-model="tab">

  
        <v-tab-item>
          <v-card color="grey lighten-4 rounded-xl ma-2 pa-4" flat>
            <v-progress-linear
            :value="statusToPercentage(candidat).value"
            :color="statusToPercentage(candidat).color"
            height="16"
            class="rounded-pill"
          >
            <span>{{ statusToPercentage(candidat).text }}</span>
          </v-progress-linear>
  
            <span v-if="candidat.rdv1 == null">Le candidat n'a pas de RDV.</span>
  
            <v-card class="grey lighten-4 mb-4" flat v-if="candidat.rdv1 != null" height="80">
              <span>RDV 1</span><br>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    :value="dateEnToFrComponent(candidat.rdv1)"
                    readonly
                    prepend-icon="mdi-calendar"
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field 
                    v-model="candidat.trigramme1"
                    readonly
                    prepend-icon="mdi-account"
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="5" v-if="candidat.commentaire_rdv1 != null">
                  <v-textarea
                    prepend-icon="mdi-comment"
                    :value="candidat.commentaire_rdv1"
                    readonly
                    rows="2"
                    no-resize
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-2"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card>
            
            
            <v-card class="grey lighten-4 mb-4" flat v-if="candidat.rdv2 != null" height="80">
              <span>RDV 2</span><br>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    :value="dateEnToFrComponent(candidat.rdv2)"
                    readonly
                    prepend-icon="mdi-calendar"
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field 
                    v-model="candidat.trigramme2"
                    readonly
                    prepend-icon="mdi-account"
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="5" v-if="candidat.commentaire_rdv2 != null">
                  <v-textarea
                    prepend-icon="mdi-comment"
                    :value="candidat.commentaire_rdv2"
                    readonly
                    rows="2"
                    no-resize
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-2"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card>
  
            <v-card class="grey lighten-4 mb-4" flat v-if="candidat.rdv3 != null" height="80">
              <span>RDV 3</span><br>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    :v-model="dateEnToFrComponent(candidat.rdv3)"
                    readonly
                    prepend-icon="mdi-calendar"
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field 
                    v-model="candidat.trigramme3"
                    readonly
                    prepend-icon="mdi-account"
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="5" v-if="candidat.commentaire_rdv3 != null">
                  <v-textarea
                    prepend-icon="mdi-comment"
                    :value="candidat.commentaire_rdv3"
                    readonly
                    rows="2"
                    no-resize
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-2"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card>
  
            <v-card class="grey lighten-4 mb-4" flat v-if="candidat.rdv4 != null" height="80">
              <span>RDV 4</span><br>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="candidat.rdv4"
                    readonly
                    prepend-icon="mdi-calendar"
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field 
                    v-model="candidat.trigramme4"
                    readonly
                    prepend-icon="mdi-account"
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="5" v-if="candidat.commentaire_rdv4 != null">
                  <v-textarea
                    prepend-icon="mdi-comment"
                    :value="candidat.commentaire_rdv4"
                    readonly
                    rows="2"
                    no-resize
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-2"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card>
  
            <v-card class="grey lighten-4 mb-4" flat v-if="candidat.offre != null" height="64">
              <span>Offre</span><br>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="candidat.offre"
                    readonly
                    prepend-icon="mdi-calendar"
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field 
                    v-model="candidat.resp_offre"
                    readonly
                    prepend-icon="mdi-account"
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field 
                    :value="dateEnToFrComponent(candidat.date_demarrage)"
                    readonly
                    prepend-icon="mdi-calendar-start"
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-2"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card>
        
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      </v-card>
    </v-dialog>
  </template>
  
  
  <script>
  import { dateEnToFr, dateInText } from '../commonFunction/date';
  
  export default {
    data: () => ({
      dialogRDV: false,
      tab: 1,
      candidat: {},
      items: [
        { text: 'RDV 1', value: 'rdv1'},
        { text: 'RDV 2', value: 'rdv2' },
        { text: 'RDV 3', value: 'rdv3' },
        { text: 'RDV 4', value: 'rdv4' },
      ],
    }),
    methods: {

      showInfosRDV(candidat) {
        this.candidat = candidat
        this.dialogRDV = true
      },
      showRDVtab() {
      //console.log("Liste des RDV du candidat :");
      //console.log("RDV 1 :", this.candidat.rdv1);
      //console.log("RDV 2 :", this.candidat.rdv2);
      //console.log("RDV 3 :", this.candidat.rdv3);
      //console.log("RDV 4 :", this.candidat.rdv4);
    },
      dateEnToFrComponent(dateString) {
        return dateEnToFr(dateString);
      },
      dateInTextComponent(dateString) {
        return dateInText(dateString);
      },
      statusToPercentage(candidat) {
        if (candidat.statut == "KO" || candidat.statut == "Désistement")
          return {text: "KO", value: 100, color: "rgb(255, 0, 0)"};
        else if (candidat.statut == "Embauché") 
          return {text: "Embauché", value: 100, color: "rgb(0, 128, 0)"};
        else if (candidat.offre != undefined && candidat.offre != "")
          return {text: "Offre", value: 84, color: "rgb(0, 255, 255)"};
        else if (candidat.rdv3 != undefined && candidat.rdv3 != "")
          return {text: "RDV 3", value: 67, color: "rgb(255, 166, 0)"};
        else if (candidat.rdv2 != undefined && candidat.rdv2 != "")
          return {text: "RDV 2", value: 50, color: "rgb(255, 255, 0)"};
        else if (candidat.rdv1 != undefined && candidat.rdv1 != "")
          return {text: "RDV 1", value: 17, color: "rgb(255, 255, 255)"};
        else
          return {text: "Sans RDV", value: 0, color: "rgb(255, 255, 255)"};
    }
  }
  }
  
  </script>